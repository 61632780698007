// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.response-container {
  width: 100%;
  margin-bottom: 2rem;
  background: rgba(255, 255, 255, 0.98);
  border-radius: 16px;
  padding: 1.5rem;
  color: #1a1a1a;
}

.prompt-section {
  width: 100%;
}

.prompt-header {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 1rem;
}

.ai-prompt {
  background: rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  padding: 1rem;
  margin: 1rem 0;
  color: #1a1a1a;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.ai-prompt pre {
  white-space: pre-wrap;
  word-wrap: break-word;
  font-family: inherit;
}

@media (max-width: 768px) {
  .response-container {
    background: rgba(255, 255, 255, 0.98);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .response-content {
    color: #1a1a1a;
  }
  
  .prompt-header {
    color: #1a1a1a;
  }
  
  .ai-prompt {
    background: rgba(0, 0, 0, 0.05);
    color: #1a1a1a;
    border: 1px solid rgba(0, 0, 0, 0.1);
  }
  
  .user-prompt {
    color: #1a1a1a;
  }
  
  .ai-prompt {
    padding: 0.8rem;
  }
  
  .ai-prompt pre {
    font-size: 0.9rem;
  }
  
  .prompt-header {
    flex-direction: column;
    align-items: flex-start;
  }
  
  .response-content {
    font-size: 0.9rem;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/ResponseSection.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,mBAAmB;EACnB,qCAAqC;EACrC,mBAAmB;EACnB,eAAe;EACf,cAAc;AAChB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,WAAW;EACX,mBAAmB;AACrB;;AAEA;EACE,+BAA+B;EAC/B,kBAAkB;EAClB,aAAa;EACb,cAAc;EACd,cAAc;EACd,oCAAoC;AACtC;;AAEA;EACE,qBAAqB;EACrB,qBAAqB;EACrB,oBAAoB;AACtB;;AAEA;EACE;IACE,qCAAqC;IACrC,wCAAwC;EAC1C;;EAEA;IACE,cAAc;EAChB;;EAEA;IACE,cAAc;EAChB;;EAEA;IACE,+BAA+B;IAC/B,cAAc;IACd,oCAAoC;EACtC;;EAEA;IACE,cAAc;EAChB;;EAEA;IACE,eAAe;EACjB;;EAEA;IACE,iBAAiB;EACnB;;EAEA;IACE,sBAAsB;IACtB,uBAAuB;EACzB;;EAEA;IACE,iBAAiB;EACnB;AACF","sourcesContent":[".response-container {\n  width: 100%;\n  margin-bottom: 2rem;\n  background: rgba(255, 255, 255, 0.98);\n  border-radius: 16px;\n  padding: 1.5rem;\n  color: #1a1a1a;\n}\n\n.prompt-section {\n  width: 100%;\n}\n\n.prompt-header {\n  display: flex;\n  align-items: center;\n  gap: 0.5rem;\n  margin-bottom: 1rem;\n}\n\n.ai-prompt {\n  background: rgba(0, 0, 0, 0.05);\n  border-radius: 8px;\n  padding: 1rem;\n  margin: 1rem 0;\n  color: #1a1a1a;\n  border: 1px solid rgba(0, 0, 0, 0.1);\n}\n\n.ai-prompt pre {\n  white-space: pre-wrap;\n  word-wrap: break-word;\n  font-family: inherit;\n}\n\n@media (max-width: 768px) {\n  .response-container {\n    background: rgba(255, 255, 255, 0.98);\n    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);\n  }\n  \n  .response-content {\n    color: #1a1a1a;\n  }\n  \n  .prompt-header {\n    color: #1a1a1a;\n  }\n  \n  .ai-prompt {\n    background: rgba(0, 0, 0, 0.05);\n    color: #1a1a1a;\n    border: 1px solid rgba(0, 0, 0, 0.1);\n  }\n  \n  .user-prompt {\n    color: #1a1a1a;\n  }\n  \n  .ai-prompt {\n    padding: 0.8rem;\n  }\n  \n  .ai-prompt pre {\n    font-size: 0.9rem;\n  }\n  \n  .prompt-header {\n    flex-direction: column;\n    align-items: flex-start;\n  }\n  \n  .response-content {\n    font-size: 0.9rem;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
